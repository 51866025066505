import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Button, Form, Input, Tooltip, Icon, InputNumber, message, Switch } from 'antd';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

@observer
class CopyeditorForm extends Component {

    handleBlocked = (value) => {
        const store = this.props.copyeditorStore;
        store.copyeditor.blocked = value;
        this.markAsDirty();
    }

    handleSave = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const store = this.props.copyeditorStore;
                await store.savePrimaryInfo(values);
                if (store.isError) {
                    message.error(store.message);
                }
            }
        });
    }

    markAsDirty = (e) => {
        const store = this.props.copyeditorStore;
        store.isPrimaryDirty = true;
    }

    validateWIPLimit = (rule, value, callback) => {

        if (value && value < 1) {
            callback('The WIP Limit should be greater than zero');
        }
        else if (value && Math.round(value) != value) {
            callback('Please provide a whole number');
        }
        else {
            callback();
        }
    };

    render() {
        const store = this.props.copyeditorStore;

        const copyeditor = store.copyeditor;

        const { getFieldDecorator } = this.props.form;

        return (
            <Form {...formItemLayout} onSubmit={this.handleSave} >
                <Form.Item
                    label={
                        <span>
                            Copyeditor Name&nbsp;
                            <Tooltip title="The Full Name of the Copyeditor">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('fullName', {
                        initialValue: copyeditor.fullName,
                        rules: [
                            {
                                required: true,
                                message: 'Please provide the full name of the copyeditor',
                                whitespace: true,
                            },
                        ],
                    })(<Input onChange={this.markAsDirty} />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Email address&nbsp;
                                <Tooltip title="The primary email address of the Copyeditor">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('emailAddress', {
                        initialValue: copyeditor.emailAddress,
                        rules: [
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please provide the primary email address of the copyeditor',
                                whitespace: true,
                            },
                        ],
                    })(<Input onChange={this.markAsDirty} />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            WIP Limit&nbsp;
                                <Tooltip title="The number of pages the copyeditor can have in a given time.">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('wipLimit', {
                        initialValue: copyeditor.wipLimit,
                        rules: [
                            {
                                required: true,
                                message: 'The number of pages the copyeditor can have in a given time',
                            },
                        ],
                    })(<InputNumber min={1} onChange={this.markAsDirty} />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            Block&nbsp;
                            <Tooltip title="To block this copyeditor from further allocation">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('blocked', {
                        initialValue: copyeditor.blocked,
                    })(<Switch checked={copyeditor.blocked} onChange={this.handleBlocked} />)}
                </Form.Item>

                <Button onClick={this.handleSave} type="primary">Next</Button>
            </Form>
        );
    }
}
export default Form.create()(CopyeditorForm);