import { decorate, observable, flow } from 'mobx';

import {  } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class MonthlyCompletionListStore {

    users = [];
    listState = PENDING;
    rowCount = 0;
  
    constructor(props) {
      this.apiProxy = props.apiProxy;   
    }
    
    fetch = flow(function* () {
      this.listState = PENDING;
  
      try {
  
        const response = yield this.apiProxy.getAsync();
        const data = yield response.json();
  
        this.users = data;
        this.rowCount = data.length;
        this.listState = DONE;
      }
      catch (e) {
        this.listState = ERROR;
      };
    });
  
    
  }
  
  decorate(MonthlyCompletionListStore, {
    listState: observable,
    users: observable,
    rowCount: observable
  })
  