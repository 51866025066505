import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer, inject } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';

const columnDefs = [
    { headerName: "Editor Name", field: "fullName" },
    { headerName: "Email", field: "emailAddress" },
    { headerName: "Average Time Taken", field: "avgTime" },
    { headerName: "Number of Completions", field: "tasksCompleted" }
];
const badgeStyle = { backgroundColor: '#52c41a' };

@inject("drawerStore")
@observer
class CopyeditorPerformanceList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.selected);
        params.api.sizeColumnsToFit();

        this.props.copyeditorPerformanceListStore.fetch();
    }

    refresh = () => {
        this.props.copyeditorPerformanceListStore.fetch();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    selected = (params) => {
        const { copyeditorPerformanceListStore } = this.props;
        copyeditorPerformanceListStore.setSelected(params.data);
    }

    render() {
        const store = this.props.copyeditorPerformanceListStore;
        const users = store.users;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle} />
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(users)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');
        const store = this.props.copyeditorPerformanceListStore;
        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(users) {
        if (this.gridApi) {
            this.gridApi.setRowData(users);
        }
    }

}

export default CopyeditorPerformanceList;
