import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { observer } from 'mobx-react';

@observer
class PageSummaryBar extends Component {

    graphHeight = () => {
        const height = window.innerHeight / 2;
        return { height: height };
    }

    barColor = (params) => {

        const store = this.props.copyeditorPageSummaryStore

        if (params.value >= store.monthlyMaxPages) {
            return "green";
        }

        if (params.value >= store.monthlyMinPages) {
            return "yellow";
        }

        return "red";
    }

    render() {

        const store = this.props.copyeditorPageSummaryStore;

        return (
            <div style={this.graphHeight()} >
                <ResponsiveBar
                    data={store.barData}
                    keys={["pages"]}
                    indexBy="month"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    groupMode="grouped"
                    colors={this.barColor}
                />
            </div>
        )
    }
}

export default PageSummaryBar;

