import Http from './Http';
import ApiUtil from './ApiUtil';
import {appStore} from './AppStore';

let backendHost = 'https://pubkit.newgen.co/submissions/api/flmj/performers';

let xLoginUrl = 'https://pubkit.newgen.co/auth';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
//   backendHost = 'http://localhost:3434/';
//   xLoginUrl = 'http://localhost:3001/auth';
// backendHost = 'https://pubsubtest.newgen.co/';
// xLoginUrl = 'https://pubkittest.newgen.co/auth';
backendHost = 'https://pubkittest.newgen.co/submissions/api/flmj/performers';

xLoginUrl = 'https://pubkittest.newgen.co/auth';
}

if (hostname === 'flmjtest.web.app') {
  backendHost = 'https://pubkittest.newgen.co/submissions/api/flmj/performers';
  xLoginUrl = 'https://pubkittest.newgen.co/auth';
}

let API_ROOT = ApiUtil.isDevelopment()
  ? 'http://localhost:3333/reportsmicro/'
  : 'https://pubsub.newgen.co/reportsmicro/';

if (window.location.origin.indexOf("flmjtest.web.app") != -1) {
  API_ROOT = 'https://pubsubtest.newgen.co/reportsmicro/';
}

const parseJson = res => res.json();
const defaultConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'access-control-allow-origin': '*',
    email: appStore.credentials.email,
    token: appStore.credentials.token,
    Origin: 'https://pubkit.newgen.co',
  },
});

export const genericApi = new Http(API_ROOT + "reports/approved_queries/", defaultConfig);

export const copyeditorsUrl = backendHost + "/copyeditors";

export const journalsUrl = backendHost + "/journals";

export const journalsDeleteUrl = backendHost + "/journals/{copyeditorUserId}/{usersJournalId}/userjournals_delete";

export const selectedCopyeditorUrl = backendHost + "/copyeditors/{copyeditorUserId}";

export const copyeditorJournalsUrl = backendHost + "/{copyeditorUserId}/journals";

export const journalAssociationUrl = backendHost + "/journals";

export const copyeditorJournalArticleTypesUrl = backendHost +"/{copyeditorUserId}/journals/{journalId}/articleTypes"

// export const unavailabilitiesUrl = {
//   get: params => genericApi.post('flmj_copyeditors_unavailabilities', params).then(parseJson),
// };

export const unavailabilitiesUrl = backendHost + "/unavailabilities";

export const allocationSummaryUrl = backendHost + "/allocationSummary";

export const wipSummaryUrl = {
  get: params => genericApi.post('flmj_wip_summary', params).then(parseJson),
};

//export const wipSummaryUrl = backendHost + "/wip"; 

export const wipallSummaryUrl = {
  get: params => genericApi.post('flmj_work_in_progress_all_users', params).then(parseJson),
};

//export const wipallSummaryUrl = backendHost + "/allwip";

export const taskAwaitingUrl = {
  get: params => genericApi.post('flmj_task_awaiting_all_users', params).then(parseJson),
};

//export const taskAwaitingUrl = backendHost + "/tasks/awaitingall";

export const waitingForStartUrl = {
  get: params => genericApi.post('flmj_task_waiting_for_start', params).then(parseJson),
};

//export const waitingForStartUrl = backendHost + /tasks/waitingforstart";

export const currentActivitiesUrl = {
  get: params => genericApi.post('flmj_work_in_progress', params).then(parseJson),
};

//export const currentActivitiesUrl = backendHost + "/wip/{copyeditorUserId}";

export const performanceReportUrl = backendHost + "/performanceReport"

export const copyeditorPerformanceReportUrl = backendHost + "/performanceReport/{copyeditorUserId}"

export const loginUrl = xLoginUrl;

export const autoLoginUrl = backendHost+'/autologin';



const Api = {

    unavailabilitiesUrl,
    wipSummaryUrl,
    wipallSummaryUrl,
    taskAwaitingUrl,
    waitingForStartUrl,
    currentActivitiesUrl
 
  };
  
  export default Api;
