import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { observer } from 'mobx-react';


@observer
class PageSummaryLine extends Component {

    graphHeight = () => {
        const height = window.innerHeight / 2;
        return { height: height };
    }

    lineColor = (params) => {
        return params.color;
    }

    render() {
        const store = this.props.copyeditorPageSummaryStore
        const lineData = this.props.type == "monthlyLine" ? store.monthlyLineData : store.accumulatedLineData;

        return (
            <div style={this.graphHeight()} >
                <ResponsiveLine
                    data={lineData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Month',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Pages',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={this.lineColor}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }]}
                />
            </div>
        )
    }
}

export default PageSummaryLine;
