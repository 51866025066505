import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';


const badgeStyle = { backgroundColor: '#52c41a' };

const defaultColDef = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true
}

@observer
class PageSummaryList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.userSelected);
        params.api.sizeColumnsToFit();

        this.props.pageSummaryStore.fetch();
    }

    refresh = () => {
        this.props.pageSummaryStore.fetch();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.6;
        return { height: height };
    }

    userSelected = (params) => {
        const months = this.props.pageSummaryStore.months;
        this.props.copyeditorPageSummaryStore.buildGraphData(months,params.data);
    }


    render() {
        const store = this.props.pageSummaryStore;
        const details = this.props.pageSummaryStore.details;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}></Badge>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(details)};
            </Fragment>
        );
    }

    cellStyle = (params) => {
        
        if(!params.data)
        {
            return;
        }

        const min = params.data.monthlyMinPages;
        const max = params.data.monthlyMaxPages;
       
        if(params.value >= max) {
            return {"textAlign": "right","color": "green"};
        }

        if(params.value >= min)  {  
            return {"textAlign": "right"};
        }
        
        return {"textAlign": "right","color":"red"};
            
    }

    decoratedHeaders = () => {
        const store = this.props.pageSummaryStore;
        const headers = store.headers;
        headers.map((item,index)=>{
            if(index > 3)
            {
                item.cellStyle = this.cellStyle;

            }
        });

        return headers;
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={this.decoratedHeaders()}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                        enableCharts={true}
                        enableRangeSelection={true}
                        defaultColDef={defaultColDef}
                        sideBar={false}
                    />
                </div>
            );
        }
    }

    renderRowData(details) {
        if (this.gridApi) {
            this.gridApi.setRowData(details);
        }
    }

}

export default PageSummaryList;
