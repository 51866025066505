import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';

const columnDefs = [
    { headerName: "Editor Name", field: "editorName" },
    { headerName: "Email", field: "email" },
    { headerName: "Completed", field: "completed" },
    { headerName: "Completed DOI", field: "completedDOI" }

];

@observer
class MonthlyCompletionList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.Selected);
        params.api.sizeColumnsToFit();

        this.props.monthlyCompletionListStore.fetch();
    }

    refresh = () => {
        this.props.monthlyCompletionListStore.fetch();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    selected = (params) => {

    }

    render() {
        const store = this.props.monthlyCompletionListStore;
        const users = store.users;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount}>
                            <Button icon="mail" />
                        </Badge>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(users)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(users) {
        if (this.gridApi) {
            this.gridApi.setRowData(users);
        }
    }

}

export default MonthlyCompletionList;
