import { decorate, observable, flow, computed } from 'mobx';
import Api from './APIEndpoints';
import { taskAwaitingUrl } from './APIEndpoints';


const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const newTaskWaiting = {
    id: 0,
    userId: 0,
    fromDate: undefined,
    toDate: undefined
}

export default class TaskWaitingListStore {

    tasks = [];
    listState = PENDING;
    rowCount = 0;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetchTask = flow(function* () {
        this.listState = PENDING;

        try {
            const params = {"where": [],"limit": "5"} 
            const data = yield Api.taskAwaitingUrl.get(params);
            //const response = yield this.apiProxy.getAsync(taskAwaitingUrl);
            //const data = yield response.json();

            this.tasks = data;
            this.rowCount = data.length;
            this.listState = DONE;
        }
        catch (e) {
            this.listState = ERROR;
        };
    });
}

decorate(TaskWaitingListStore, {
    listState: observable,
    tasks: observable,
    rowCount: observable
})