import { decorate, observable, action } from 'mobx';
import { copyeditorJournalArticleTypesUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class ArticleTypeStore {

    state = PENDING;
    copyeditorUserId=-1;
    journalId = -1;
    journalArticleTypes = [];
    selected=[];
    showModal = false;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    /**
     * List of article types associated with a Journal, which in turn is
     * associated with a Copyeditor.
     * 
     */
    fetchJournalArticleTypes = async (copyeditorUserId,journalId) => {

        this.state = PENDING;
        this.copyeditorUserId = copyeditorUserId;
        this.journalId = journalId;

        this.journalArticleTypes = [];

        const url = copyeditorJournalArticleTypesUrl.replace("{copyeditorUserId}",copyeditorUserId).replace("{journalId}",journalId);

        try {
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();

            this.journalArticleTypes = data;
            this.captureSelected(data);

            this.state = DONE;
            this.showModal = true;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    captureSelected = (data) => {
        this.selected = [];
        data.map(item=>{
            if(item.selected)
            {
                this.selected.push(item.articleTypeId)
            }
        })
    }

    save = async(values) => {

        this.state = PENDING;
        const url = copyeditorJournalArticleTypesUrl.replace("{copyeditorUserId}",this.copyeditorUserId).replace("{journalId}",this.journalId);   
        try {

            const response = await this.apiProxy.asyncPost(url, {...values.selected });
            const data = await response.json();
            this.state = DONE;
            this.showModal = false;
        }

        catch (e) {
            this.state = ERROR;
        }
    }

    
}

decorate(ArticleTypeStore, {
    state: observable,
    copyeditorUserId: observable,
    journalId:observable,
    journalArticleTypes: observable,
    selected:observable,
    showModal: observable,

    fetchJournalArticleTypes: action,
    captureSelected:action,
    save:action
 
})