import { decorate, observable, action } from 'mobx';

import { allocationSummaryUrl} from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class PageSummaryStore {

    listState = PENDING;

    headers = [];
    details = [];
    rowCount = 0;

    months = [];

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetch = async() => {

        this.listState = PENDING;

        try {

            const response = await this.apiProxy.getAsync(allocationSummaryUrl);
            const data = await response.json();

            //const data = require("./test_data/summary.json");
        
            this.captureMonths(data.headers);
            this.setDefaultCellStyle(data.headers);

            this.headers = data.headers;
            this.details = data.details;
            this.rowCount = data.details.length;
        
            this.listState = DONE;
        }
        catch (e) {
            this.listState = ERROR;
        };
    };

    captureMonths = (dataHeaders)=> {
        this.months = [];
        dataHeaders.map((item,index)=> {
            if(index > 3)
            {
                this.months.push(item);
            }
        })
    }

    setDefaultCellStyle = (dataHeaders) => {
        dataHeaders.map((item,index) => {
            if(item.field=="monthlyMinPages" || item.field=="monthlyMaxPages")
            {
                item["cellStyle"] = {"textAlign": "right"}
            }
        })
    }

}

decorate(PageSummaryStore, {
    listState: observable,
    headers: observable,
    details: observable,
    rowCount: observable,
    fetch: action,
    captureMonths: action,
    setDefaultCellStyle: action
})