import { decorate, observable, flow, computed } from 'mobx';
import Api from './APIEndpoints';
import { unavailabilitiesUrl, copyeditorsUrl } from './APIEndpoints';
import { drawerStore } from './DrawerStore';


const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const newUnavailability = {
  id: 0,
  userId: 0,
  fromDate: undefined,
  toDate: undefined
}

export default class AvailabilityListStore {

  availabilities = [];
  listState = PENDING;
  copyeditors = [];
  rowCount = 0;
  selectedUnavailability = newUnavailability;

  constructor(props) {
    this.apiProxy = props.apiProxy;
  }

  fetch = flow(function* () {
    this.listState = PENDING;

    try {
      // const params = {"where": [],"limit": "5"}
      // const data = yield Api.unavailabilitiesUrl.get(params);
      const response = yield this.apiProxy.getAsync(unavailabilitiesUrl);
      const data = yield response.json();

      this.availabilities = this.toAvalibityList(data);
      this.rowCount = data.length;
      this.listState = DONE;
    }
    catch (e) {
      this.listState = ERROR;
      console.log(e);
    };
  });

  updateSelectedUnavailability = (valueMap) => {
    this.selectedUnavailability = { ...this.selectedUnavailability, ...valueMap };
  }

  toAvalibityList(data) {
    const list = [];
    data.forEach(element => {
      list.push({
        ...element,
        period: element.fromDate + ' to ' + element.toDate
      })
    });
    return list;
  }

  onSelect = (data) => {
    this.selectedUnavailability = { id: data.id, fromDate: data.fromDate, toDate: data.toDate, userId: data.userId };
    drawerStore.toggle();
  }

  save = async () => {
    this.listState = PENDING;
    try {
      const response = await this.apiProxy.asyncPost(unavailabilitiesUrl, this.selectedUnavailability);
      const data = await response.json();
      this.fetch();
      this.reset();
      drawerStore.toggle();
      this.listState = DONE;
    } catch (e) {
      this.listState = ERROR;
    }

  }

  delete = async () => {
    this.listState = PENDING;
    try {
      const response = await this.apiProxy.asyncDelete(unavailabilitiesUrl, this.selectedUnavailability.id);
      const data = await response.json();
      this.fetch();
      this.reset();
      drawerStore.toggle();
      this.listState = DONE;
    } catch (e) {
      this.listState = ERROR;
    }
  }

  fetchCopyEditors = async () => {
    this.listState = PENDING;

    try {
      const response = await this.apiProxy.getAsync(copyeditorsUrl);
      const data = await response.json();

      this.copyeditors = data;
      this.listState = DONE;
    }
    catch (e) {
      console.log(e);
      this.listState = ERROR;
    };
  }

  get isLoading() {
    return this.listState === PENDING;
  }

  get isValid() {
    return this.selectedUnavailability.userId !== 0
      && this.selectedUnavailability.fromDate !== undefined
      && this.selectedUnavailability.toDate !== undefined;

  }

  reset = () => {
    this.selectedUnavailability = newUnavailability;
  }

}

decorate(AvailabilityListStore, {
  listState: observable,
  availabilities: observable,
  copyeditors: observable,
  rowCount: observable,
  selectedUnavailability: observable,

  isLoading: computed,
  isValid: computed,
})
