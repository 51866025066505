import { decorate, observable, flow, action } from 'mobx';
import Api from './APIEndpoints';
import { currentActivitiesUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class TaskListStore {

    tasks = [];
    copyeditorName = '';

    showDrawer = false;
    listState = PENDING;
    rowCount = 0;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetchTasks = flow(function* (userId, fullName) {
        this.listState = PENDING;
        this.showDrawer = true;
        this.copyeditorName = fullName;
        try {
            // const url = currentActivitiesUrl.replace("{copyeditorUserId}",userId);
            // const response = yield this.apiProxy.getAsync(url);
            // const data = yield response.json();

            const params = { "where": { "userId": userId.toString()}, "limit": "5" }
            const data = yield Api.currentActivitiesUrl.get(params);

            this.tasks = data;
            this.rowCount = data.length;
            this.listState = DONE;            
        }
        catch (e) {
            this.listState = ERROR;
        };
    });
}

decorate(TaskListStore, {
    listState: observable,
    tasks: observable,
    rowCount: observable,
    copyeditorName: observable,
    showDrawer: observable,
})