import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Drawer } from 'antd';
import CustomSelect from '../util/CustomSelect';
import { DatePicker } from 'antd';
import { Button } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const style = {display:'flex',flexDirection:'column'}
const label = {marginTop:'10px',fontWeight:'bold'}

@inject("drawerStore")
@observer
class AvailabilityDrawer extends Component {

    close = () => {
        const { drawerStore } = this.props;
        drawerStore.toggle();
    }

    onUserSelected = (value) => {
        const { availabilityListStore } = this.props;
        availabilityListStore.updateSelectedUnavailability({ userId: value });
    }

    onRangeSelected = (date, dateString) => {
        const { availabilityListStore } = this.props;
        availabilityListStore.updateSelectedUnavailability({ fromDate: dateString[0] });
        availabilityListStore.updateSelectedUnavailability({ toDate: dateString[1] });
    }

    save = () => {
        const { availabilityListStore } = this.props;
        availabilityListStore.save();
    }

    delete = () => {
        const { availabilityListStore } = this.props;
        availabilityListStore.delete();
    }

    disabledDate = (current) => {
        return current && current < moment().endOf('day');
      }

    render() {
        const { drawerStore, availabilityListStore } = this.props;
        const { selectedUnavailability: { id, fromDate, toDate, userId } } = availabilityListStore;
        const datesArray = id === 0 ? [] : [moment(fromDate), moment(toDate)];
        const title = id === 0 ? "Add Unavailability" : "Edit Unavailability";
        return (
            <Drawer style= {style} title={title} width={"40%"} closable={true} onClose={this.close} visible={drawerStore.showDrawer} destroyOnClose>
                <div style={label}>Copyeditor:</div>
                <div>    
                    <CustomSelect key='ce' disabled = {id!==0} defaultValue={userId} options={availabilityListStore.copyeditors} onSelectCallBack={this.onUserSelected} />
                </div>
                <div style={label}>Unavailability Period:</div>
                <div>    
                    <RangePicker disabled = {id!==0} disabledDate = {this.disabledDate} defaultValue={datesArray} onChange={this.onRangeSelected} />
                </div>
                {id === 0 && <div style={{ marginTop: '10px' }}>
                    <Button disabled={!availabilityListStore.isValid || availabilityListStore.isLoading} loading={availabilityListStore.isLoading} type="primary" onClick={this.save}>Save</Button></div>}
                {id !== 0 && <div style={{ marginTop: '10px' }}>
                    <Button loading={availabilityListStore.isLoading} type="danger" onClick={this.delete}>Delete</Button></div>}
            </Drawer>
        );
    }
}

export default AvailabilityDrawer