import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Select,Tooltip, Icon,InputNumber } from 'antd';


const {Option} = Select 

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


@observer
class CopyeditorJournalForm extends Component {

    saveJournal = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const store = this.props.copyeditorJournalStore;
                store.saveJournal(values);
            }
        });
    }

    renderJournals = (journals) => {
        return (
            journals.map(item=>
                <Option value={item.journalId} key={item.journalId}>{item.shortTitle}, {item.name}</Option>
            )
        )
    }

    render() {

        const store = this.props.copyeditorJournalStore;
        const journals = store.journals;

        const { getFieldDecorator } = this.props.form;
        return (

            <Form {...formItemLayout} onSubmit={this.saveJournal} >
                <Form.Item
                    label={
                        <span>
                            Journal&nbsp;
                                <Tooltip title="Select the Journal to Associate the Copyeditor">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('journalId', {
                        rules: [
                            {
                                required: true,
                                message: 'Please Select the Journal',
                            },
                        ],
                    })(
                        <Select 
                            showSearch
                            placeholder="Select a Journal"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                            }
                        >
                            {this.renderJournals(journals)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            Priority&nbsp;
                                <Tooltip title="The preference of this copyeditor to this journal. 1 is the highest.">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('priority', {
                        rules: [
                            {
                                required: true,
                                message: 'The preference of this copyeditor to this journal. 1 is the highest.',
                            },
                        ],
                    })(<InputNumber min={1}/>)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            WIP Limit&nbsp;
                                <Icon type="question-circle-o" />
                        </span>
                    }
                >
                    {getFieldDecorator('wipLimit', {
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    })(<InputNumber min={0}/>)}
                </Form.Item>

                <Button onClick={this.saveJournal} type="primary">Save</Button>    
            </Form >

        );
    }

}

export default Form.create()(CopyeditorJournalForm);
