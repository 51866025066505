import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Tabs } from 'antd';
import PageSummaryBar from './PageSummaryBar';
import PageSummaryLine from './PageSummaryLine';

const { TabPane } = Tabs;

@observer
class GraphTabs extends Component {

    changeTab = (tabIndex) => {
        const store = this.props.copyeditorPageSummaryStore;
        store.activeTab = tabIndex;
    }

    renderTab = (tabKey) => {

        switch (tabKey) {

            case "accumulatedLine":
                return <PageSummaryLine type={tabKey} copyeditorPageSummaryStore={this.props.copyeditorPageSummaryStore} />

            case "monthlyLine":
                return <PageSummaryLine type={tabKey} copyeditorPageSummaryStore={this.props.copyeditorPageSummaryStore} />

            case "monthlyBar":
                return <PageSummaryBar copyeditorPageSummaryStore={this.props.copyeditorPageSummaryStore} />
 
            default:
                return <PageSummaryBar copyeditorPageSummaryStore={this.props.copyeditorPageSummaryStore} />

        }

    }

    buildTabs = (store) => {
        return (
            <Tabs activeKey={store.activeTab} tabPosition='top' style={{ height: "100%" }} onChange={this.changeTab}>
                {[...store.graphTabs].map((menu, index) => (
                    <TabPane tab={menu.label} key={index} >
                        {this.renderTab(menu.key)}
                    </TabPane>
                ))}
            </Tabs>
        )
    }

    render() {
        const store = this.props.copyeditorPageSummaryStore;
        return (
            <div>
                <PageHeader title={store.fullName} subTitle = {store.emailAddress}/>
                {this.buildTabs(store)}
            </div>
        );
    }

}

export default GraphTabs