import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';

const columnDefs = [
    { headerName: "Journal Name", field: "journalName" },
    { headerName: "Journal Short Name", field: "journalShortName" },
    { headerName: "Number of Copyeditors", field: "" },
    { headerName: "Average Time Taken", field: "avgTime" }
];

const addStyle = { backgroundColor: 'black',color:'white' };
const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class JournalPerformanceList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.journalSelected);
        params.api.sizeColumnsToFit();

        this.props.journalPerformanceListStore.fetchJournals();
    }


    refresh = () => {
        this.props.journalPerformanceListStore.fetchJournals();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    journalSelected = (params) => {
    }

    newJournal = () => {

    }

    render() {
        const store = this.props.journalPerformanceListStore;
        const journals = store.journals;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="add" onClick={this.newJournal} style={addStyle} icon="plus">New</Button>,
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}/>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(journals)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(journals) {
        if (this.gridApi) {
            this.gridApi.setRowData(journals);
        }
    }
}

export default JournalPerformanceList;
