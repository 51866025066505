import React, { Component } from 'react';
import { inject } from 'mobx-react';

import AvailabilityDrawer from './AvailabilityDrawer';
import AvailabilityList from './AvailabilityList';

import AvailabilityListStore from '../stores/AvailabilityListStore';

@inject("appStore")
class AvailabilityUI extends Component {

    constructor(props) {
        super(props);
        this.availabilityListStore = new AvailabilityListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {
        return (
            <div>
                <AvailabilityList availabilityListStore={this.availabilityListStore} />
                <AvailabilityDrawer availabilityListStore={this.availabilityListStore} />
            </div>
        )
    }

}

export default AvailabilityUI;
