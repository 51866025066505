import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';
import { yesOrNo } from '../stores/Util';

const columnDefs = [
    { headerName: "User Id", field: "userId" },
    { headerName: "Name", field: "fullName", sort:'asc'},
    { headerName: "Email", field: "emailAddress" },
    { headerName: "WIP Limit", field: "wipLimit" },
    { headerName: "Journals", field: "journalNames" },
    { headerName: "Blocked", field: "blocked", valueFormatter: params => yesOrNo(params.data["blocked"]) },
];

const addStyle = { backgroundColor: 'black',color:'white' };
const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class CopyeditorList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.selected);
        params.api.sizeColumnsToFit();

        this.props.copyeditorListStore.fetchCopyeditors();
    }

    refresh = () => {
        this.props.copyeditorListStore.fetchCopyeditors();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    selected = (params) => {
        this.props.copyeditorStore.fetchCopyeditor(params.data.userId);
    }

    new = () => {
        this.props.copyeditorStore.newCopyeditor();
    }

    render() {
        const store = this.props.copyeditorListStore;
        const copyeditors = store.copyeditors;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="add" onClick={this.new} style={addStyle} icon="plus">New</Button>,
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}/>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(copyeditors)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                        enableCharts={true}
                        enableRangeSelection={true}
                    />
                </div>
            );
        }
    }

    renderRowData(copyeditors) {
        if (this.gridApi) {
            this.gridApi.setRowData(copyeditors);
        }
    }
}

export default CopyeditorList;
