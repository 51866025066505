import React, { Component } from 'react';
import { inject } from 'mobx-react';

import PageSummaryStore from '../stores/PageSummaryStore';
import CopyeditorPageSummaryStore from '../stores/CopyeditorPageSummaryStore';

import PageSummaryList from './PageSummaryList';
import GraphTabs from './GraphTabs';

@inject("appStore")
class PageSummaryUI extends Component {

    constructor(props) {
        super(props);
        this.pageSummaryStore = new PageSummaryStore({ apiProxy: props.appStore.apiProxy });
        this.copyeditorPageSummaryStore = new CopyeditorPageSummaryStore({ apiProxy: props.appStore.apiProxy });

    }

    render() {
       
        return (
            <div>
                <PageSummaryList pageSummaryStore={this.pageSummaryStore} copyeditorPageSummaryStore={this.copyeditorPageSummaryStore}/>
                <GraphTabs copyeditorPageSummaryStore={this.copyeditorPageSummaryStore}  />
            </div>
        )
    }

}

export default PageSummaryUI;

