import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import TaskList from './TaskList';

@observer
class WIPDrawer extends Component {

    close = () => {
        const store = this.props.taskListStore;
        store.showDrawer = false;
    }


    render() {
        const store = this.props.taskListStore;
        const title = store.copyeditorName;

        return (
            <Drawer title={title} width={"45%"} closable={true} onClose={this.close} visible={store.showDrawer} destroyOnClose>
                <TaskList taskListStore={store}/>
            </Drawer>
        );
    }
}

export default WIPDrawer