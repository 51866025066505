import React, { Component } from 'react';
import { inject } from 'mobx-react';

import WaitingForStartList from './WaitingForStartList';
import WaitingForStartListStore from '../stores/WaitingForStartListStore';


@inject("appStore")
class WaitingForStartUI extends Component {

    constructor(props) {
        super(props);
        this.waitingForStartListStore = new WaitingForStartListStore({ apiProxy: props.appStore.apiProxy });
        
    }

    render() {

        return (
            <div>
                <WaitingForStartList waitingForStartListStore={this.waitingForStartListStore} />
                
            </div>
        )
    }

}

export default WaitingForStartUI;