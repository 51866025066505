import React, { Component } from 'react';
import { inject } from 'mobx-react';

import JournalPerformanceDrawer from './JournalPerformanceDrawer';
import JournalPerformanceList from './JournalPerformanceList';

import JournalPerformanceListStore from '../stores/JournalPerformanceListStore';


@inject("appStore")
class JournalPerformanceUI extends Component {

    constructor(props) {
        super(props);
        this.journalPerformanceListStore = new JournalPerformanceListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {

        return (
            <div>
                <JournalPerformanceList journalPerformanceListStore={this.journalPerformanceListStore} />
                <JournalPerformanceDrawer  />
            </div>
        )
    }

}

export default JournalPerformanceUI;

