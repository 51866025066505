import React, { Component } from 'react';
import { inject } from 'mobx-react';

import TaskWaitingList from './TaskWaitingList';

import TaskWaitingListStore from '../stores/TaskWaitingListStore';

@inject("appStore")
class TaskWaitingUI extends Component {

    constructor(props) {
        super(props);
        this.taskWaitingListStore = new TaskWaitingListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {
        return (
            <div>
                <TaskWaitingList taskWaitingListStore={this.taskWaitingListStore} />
            </div>
        )
    }
}

export default TaskWaitingUI;
