import { decorate, observable, flow } from 'mobx';
import Api from './APIEndpoints';
import { wipSummaryUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class WIPListStore {

    users = [];
    listState = PENDING;
    rowCount = 0;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetch = flow(function* () {
        this.listState = PENDING;

        try {
            const params = {"where": [],"limit": "5"} 
            //const response = yield this.apiProxy.getAsync(wipSummaryUrl(params));
            const data = yield Api.wipSummaryUrl.get(params);
            //const data = yield response.json();

            this.users = data;
            this.rowCount = data.length;
            this.listState = DONE;
        }
        catch (e) {
            this.listState = ERROR;
        };
    });
}

decorate(WIPListStore, {
    listState: observable,
    users: observable,
    rowCount: observable
})