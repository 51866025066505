import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';
import {humanizeDate} from '../stores/Util';

const columnDefs = [
    { headerName: "Copyeditor", field: "fullName", sort:'asc' },
    { headerName: "Email Id", field: "emailAddress" },
    { headerName: "Monthly Max Pages", field: "monthlyMaxPages" },
    { headerName: "Monthly Min Pages", field: "monthlyMinPages" },
    { headerName: "WIP Limit", field: "wipLimit" },
    { headerName: "Current Load", field: "currentLoad" },
    { headerName: "Last Offered Date", field: "lastGivenDate"},
    { headerName: "Earliest Due Date", field: "earliestDueDate" },

];

const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class WIPSummaryList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.userSelected);
        params.api.sizeColumnsToFit();

        this.props.wipListStore.fetch();
    }

    refresh = () => {
        this.props.wipListStore.fetch();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    userSelected = (params) => {
        this.props.taskListStore.fetchTasks(params.data.userId, params.data.fullName);
    }

    render() {
        const store = this.props.wipListStore;
        let users = store.users;
        for(var i = 0; i < users.length; i++)
        {
            users[i]["lastGivenDate"] =  humanizeDate(users[i]["lastGivenDate"])
            users[i]["earliestDueDate"] =  humanizeDate(users[i]["earliestDueDate"])
        }
       
        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}></Badge>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(users)}

            </Fragment>
        );
    }


    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(users) {
        if (this.gridApi) {
            this.gridApi.setRowData(users);
        }
    }
}

export default WIPSummaryList;
