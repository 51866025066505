import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';
import {humanizeDate} from '../stores/Util';
import { yesOrNo } from '../stores/Util';

const columnDefs = [
    { headerName: "Journal Short Name", field: "journalShortName" },
    { headerName: "Article Type", field: "articleType"},
    { headerName: "Auto Allocation", field: "autoAllocation",  valueFormatter: params => yesOrNo(params.data["autoAllocation"])  },
    { headerName: "Project Name", field: "projectName" },
    { headerName: "Task Name", field: "taskName" },
    { headerName: "MS Pages", field: "msPages"},
    { headerName: "Actual Start Date", field: "actualStartDate"},
    { headerName: "Expected End Date", field: "expectedEndDate"}
    
];

const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class TaskList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
        const store = this.props.taskListStore;
        this.renderRowData(store.tasks);   
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.5;
        return { height: height };
    }

    render() {
        const store = this.props.taskListStore;
        let tasks = store.tasks;
        for(var i = 0; i < tasks.length; i++)
        {
            tasks[i]["actualStartDate"] =  humanizeDate(tasks[i]["actualStartDate"])
            tasks[i]["expectedEndDate"] =  humanizeDate(tasks[i]["expectedEndDate"])
        }
       
        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}/>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(tasks)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
    }

    renderRowData(tasks) {
        if (this.gridApi) {
            this.gridApi.setRowData(tasks);
        }
    }
}

export default TaskList;
