import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import CopyeditorJournalForm from './CopyeditorJournalForm';

@observer
class CopyeditorJournalModal extends Component {

    handleCancel = () => {
        this.props.copyeditorJournalStore.showModal = false;
    }

    render() {
        const visible = this.props.copyeditorJournalStore.showModal;

        return (
            <Modal title="Associate A Journal"
                visible={visible}
                onCancel={this.handleCancel}
                footer={[]}
            >
                <CopyeditorJournalForm copyeditorJournalStore = {this.props.copyeditorJournalStore} />
            </Modal>
        )
    }
}
export default CopyeditorJournalModal;