import React, { Component} from 'react';
import { Provider } from 'mobx-react';
import { Layout } from 'antd';
import TitleBar from './components/TitleBar';
import SelectedComponent from './components/SelectedComponent'
import NavigationSider from './components/NavigationSider';
import { appStore } from './components/stores/AppStore';
import {drawerStore} from './components/stores/DrawerStore';

const { Content } = Layout;

export default class App extends Component {

  render() {

    return (
      <div>
        <Provider appStore={appStore}  drawerStore = {drawerStore}>
          <Layout style={{minHeight: '100vh'}} >
            <NavigationSider />
            <Layout>
            <TitleBar></TitleBar>
              <Content>
                <SelectedComponent />
              </Content>
            </Layout>
          </Layout>
        </Provider>
      </div>
    );
  }
}
