import React, { Component } from 'react';
import { inject } from 'mobx-react';

import CopyeditorDrawer from './CopyeditorDrawer';
import CopyeditorList from './CopyeditorList';

import CopyeditorListStore from '../stores/CopyeditorListStore';
import CopyeditorStore from '../stores/CopyeditorStore';
import CopyeditorJournalListStore from '../stores/CopyeditorJournalListStore';
import CopyeditorJournalStore from '../stores/CopyeditorJournalStore';
import ArticleTypeStore from '../stores/ArticleTypeStore';

@inject("appStore")
class CopyeditorUI extends Component {

    constructor(props) {
        super(props);
        this.copyeditorListStore = new CopyeditorListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {

        const copyeditorJournalListStore = new CopyeditorJournalListStore ({apiProxy:this.props.appStore.apiProxy});

        const copyeditorJournalStore = new CopyeditorJournalStore ({
                apiProxy:this.props.appStore.apiProxy,
                copyeditorJournalListStore:copyeditorJournalListStore});

        const articleTypeStore = new ArticleTypeStore({apiProxy: this.props.appStore.apiProxy}); 

        const copyeditorStore = new CopyeditorStore({ 
                apiProxy: this.props.appStore.apiProxy,
                copyeditorListStore: this.copyeditorListStore,
                copyeditorJournalListStore: copyeditorJournalListStore, 
                copyeditorJournalStore:copyeditorJournalStore,
                articleTypeStore:articleTypeStore});

               

        return (
            <div>
                <CopyeditorList copyeditorListStore={this.copyeditorListStore} copyeditorStore={copyeditorStore} />
                <CopyeditorDrawer copyeditorStore = {copyeditorStore} />
            </div>
        )
    }

}

export default CopyeditorUI;

