import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import ArticleTypeForm from './ArticleTypeForm';

@observer
class ArticleTypeModal extends Component {

    handleCancel = () => {
        this.props.articleTypeStore.showModal = false;
    }

    render() {
        const visible = this.props.articleTypeStore.showModal;

        return (
            <Modal title="Select or Unselect Article Types"
                visible={visible}
                onCancel={this.handleCancel}
                footer={[]}
            >
                <ArticleTypeForm articleTypeStore = {this.props.articleTypeStore} />
            </Modal>
        )
    }
}
export default ArticleTypeModal;