import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer, inject } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';

const columnDefs = [
    { headerName: "Copyeditor", field: "editorName", sort:'asc' },
    { headerName: "Email", field: "email" },
    { headerName: "Period", field: "period" }
];
const addStyle = { backgroundColor: 'black',color:'white' };
const badgeStyle = { backgroundColor: '#52c41a' };

@inject("drawerStore")
@observer

class AvailabilityList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.selected);
        params.api.sizeColumnsToFit();

        this.props.availabilityListStore.fetch();
        this.props.availabilityListStore.fetchCopyEditors();
    }

    refresh = () => {
        this.props.availabilityListStore.fetch();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    selected = (params) => {
        this.props.availabilityListStore.onSelect(params.data);
    }

    new = () => {
        const {drawerStore,availabilityListStore} = this.props;
        availabilityListStore.reset();
        drawerStore.toggle();
    }

    render() {
        const store = this.props.availabilityListStore;
        const availabilities = store.availabilities;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="add" onClick={this.new} style={addStyle} icon="plus">New</Button>,
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}/>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(availabilities)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(availabilities) {
        if (this.gridApi) {
            this.gridApi.setRowData(availabilities);
        }
    }

}

export default AvailabilityList;
