import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Tabs, message } from 'antd';

import CopyeditorForm from './CopyeditorForm';
import CopyeditorJournalList from './CopyeditorJournalList';

const { TabPane } = Tabs;

@observer
class CopyeditorDrawer extends Component {

    close = () => {
        const store = this.props.copyeditorStore;
        store.showDrawer = false;
        store.activeTab = "0";
    }

    /**
     * Allow the user to change the tab only when the primary tab has been filled and saved.
     */
    changeTab = (tabIndex) => {

        const store = this.props.copyeditorStore;

        if(store.isPrimaryDirty ===true) {
            message.warning("You have modified the primary information. Please save before navigating to any tab.");
            return;
        }

        if(store.copyeditor.userId === 0) {
            message.warning("Please save the pirmary information before navigating to any tab.");
            return;
        }

        store.changeTab(tabIndex);
    }

    renderTab = (tabKey) => {

            switch(tabKey) {

                case "primary":
                    return <CopyeditorForm copyeditorStore={this.props.copyeditorStore} />
                
                case "journals":
                    return <CopyeditorJournalList copyeditorStore={this.props.copyeditorStore} />
       
                default:
                  return <CopyeditorForm copyeditorStore={this.props.copyeditorStore} />
                    
            }

    }

    buildTabs = (store) => {
        return (
            <Tabs activeKey={store.activeTab} tabPosition='top' style={{ height: "100%" }} onChange={this.changeTab}>
              {[...store.drawerTabs].map((menu, index) => (
                <TabPane tab={menu.label} key={index} >
                  {this.renderTab(menu.key)}
                </TabPane>
              ))}
            </Tabs>
          )
    }


    render() {
        const store = this.props.copyeditorStore;
        const title = store.copyeditor.userId === 0 ? "New Copyeditor" : store.copyeditor.fullName;
        return (
            <Drawer title={title} width={"45%"} closable={true} onClose={this.close} visible={store.showDrawer} destroyOnClose>
                {this.buildTabs(store)}
            </Drawer>
        );
    }
}

export default CopyeditorDrawer