import React, { Component } from 'react';
import { inject } from 'mobx-react';

import WIPALLSummaryList from './WIPALLSummaryList';
import WIPALLListStore from '../stores/WIPALLListStore';


@inject("appStore")
class WIPALLUI extends Component {

    constructor(props) {
        super(props);
        this.wipallListStore = new WIPALLListStore({ apiProxy: props.appStore.apiProxy });
        
    }

    render() {

        return (
            <div>
                <WIPALLSummaryList wipallListStore={this.wipallListStore} />
                
            </div>
        )
    }

}

export default WIPALLUI;

