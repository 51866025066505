import React, { Component } from 'react';
import { inject } from 'mobx-react';

import MonthlyCompletionDrawer from './MonthlyCompletionDrawer';
import MonthlyCompletionList from './MonthlyCompletionList';

import MonthlyCompletionListStore from '../stores/MonthlyCompletionListStore';

@inject("appStore")
class MonthlyCompletionUI extends Component {

    constructor(props) {
        super(props);
        this.monthlyCompletionListStore = new MonthlyCompletionListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {

        return (
            <div>
                <MonthlyCompletionList monthlyCompletionListStore={this.monthlyCompletionListStore} />
                <MonthlyCompletionDrawer  />
            </div>
        )
    }

}

export default MonthlyCompletionUI;

