import React, { Component } from 'react';

import { observer, inject } from 'mobx-react';

import Login from './LoginScreen';
import About from './About';

import CopyeditorUI from './copyeditor/CopyeditorUI';
import AvailabilityUI from './copyeditor/AvailabilityUI';
import WIPUI from './copyeditor/WIPUI';
import WIPALLUI from './copyeditor/WIPALLUI';
import WaitingForStartUI from './copyeditor/WaitingForStartUI';
import CopyeditorPerformanceUI from './copyeditor/CopyeditorPerformanceUI';
import JournalPerformanceUI from './copyeditor/JournalPerformanceUI';
import MonthlyCompletionUI from './copyeditor/MonthlyCompletionUI';
import TaskWaitingUI from './copyeditor/TaskWaitingUI';
import PageSummaryUI from './copyeditor/PageSummaryUI';


@inject("appStore")
@observer
class SelectedComponent extends Component {

    evaluateComponent() {
        const appStore = this.props.appStore;
        switch (appStore.currentComponent.key) {
            case 'master': {
                return <CopyeditorUI />;
            }
            case 'availability': {
                return <AvailabilityUI />;
            }
            case 'wip': {
                return <WIPUI />;
            }
            case 'allwip': {
                return <WIPALLUI />;
            }
            case 'waitingForStart': {
                return <WaitingForStartUI />;
            }
            case 'pageSummary': {
                return <PageSummaryUI />;
            }
            case 'tasksWaiting': {
                return <TaskWaitingUI />;
            }
            case 'copyeditorPerformance': {
                return <CopyeditorPerformanceUI />;
            }
            case 'journalPerformance': {
                return <JournalPerformanceUI />;
            }
            case 'monthlyCompletion': {
                return <MonthlyCompletionUI />;
            }
            case 'Login': {
                return <Login />;
            }
            case 'About': {
                return <About />;
            }
            default: {
                return <About />;
            }
        }
    }
    
    render() {
        return (this.evaluateComponent());
    }
}


export default SelectedComponent;