import { decorate, observable, flow } from 'mobx';
import Api from './APIEndpoints';
import { copyeditorsUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class CopyeditorListStore {

  copyeditors = [];
  listState = PENDING;
  rowCount = 0;

  constructor(props) {
    this.apiProxy = props.apiProxy;   
  }
  
  fetchCopyeditors = flow(function* () {
    this.listState = PENDING;

    try {
      const response = yield this.apiProxy.getAsync(copyeditorsUrl);
      const data = yield response.json();
      // const params = {"where": [],"limit": "5"}
      // const data = yield Api.copyeditorsUrl.get(params);

      this.copyeditors = data;
      this.rowCount = data.length;
      this.listState = DONE;
    }
    catch (e) {
      this.listState = ERROR;
    };
  });

  
}

decorate(CopyeditorListStore, {
  listState: observable,
  copyeditors: observable,
  rowCount: observable
})
