import { decorate, observable, flow, action } from 'mobx';

import { selectedCopyeditorUrl,copyeditorsUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const freshCopyeditor = {
    userId: 0,
    fullName: '',
    emailAddress: '',
    wipLimit: 1,
    monthlyMinPages: 1,
    monthlyMaxPages: 1,
    blocked: false
}

export default class CopyeditorStore {

    copyeditor = {};

    showDrawer = false;
    state = PENDING;
    activeTab = "0";

    drawerTabs = [
        {
            "label": "Primary",
            "key": "primary",
        },
        {
            "label": "Journals",
            "key": "journals",
        }
    ];

    isPrimaryDirty = false;
    isError = false;
    message='';

    constructor(props) {
        this.apiProxy = props.apiProxy;
        this.copyeditorListStore = props.copyeditorListStore;
        this.copyeditorJournalListStore = props.copyeditorJournalListStore;
        this.copyeditorJournalStore = props.copyeditorJournalStore;
        this.articleTypeStore = props.articleTypeStore;
    }

    newCopyeditor = () => {
        this.copyeditor = freshCopyeditor;
        this.activeTab="0";
        this.state=DONE;
        this.isPrimaryDirty = false;
        this.showDrawer = true;
    }

    fetchCopyeditor = flow(function *(userId) {
        this.state = PENDING;
        this.isError = false;
        this.message = '';

        const url = selectedCopyeditorUrl.replace('{copyeditorUserId}',userId);

        try {
            const response = yield this.apiProxy.getAsync(url);
            const data = yield response.json(); 

            this.copyeditor = data;

            this.copyeditorJournalListStore.accept(this.copyeditor.userId);
            
            this.state = DONE;
            this.isPrimaryDirty = false;
            this.showDrawer = true;
            this.activeTab='0';
        }
        catch(e)
        {
            this.state = ERROR;
        }

    });

    changeTab = (tabIndex) => { 
        this.activeTab=tabIndex;
    }

    savePrimaryInfo = async(values) => {
        
        this.state = PENDING;
        this.isError = false;
        this.message = '';

        try {
            const response = await this.apiProxy.asyncPost(copyeditorsUrl, {userId:this.copyeditor.userId,...values});
            const data = await response.json();

            if(data.error == true)
            {
                this.isError = true;
                this.message = data.detailedErrorMessage;
                this.state = DONE;
                return;
            }

            this.copyeditor = data;

            this.informPeerStores();

            this.isPrimaryDirty = false;
            this.activeTab='1';
            this.state = DONE;
        }
        catch(e) {
            this.state = ERROR;
        }
    }

    informPeerStores = () => {
        this.copyeditorJournalListStore.accept(this.copyeditor.userId);
        this.copyeditorListStore.fetchCopyeditors();
    }

}

decorate(CopyeditorStore, {

    copyeditor: observable,

    state: observable,
    activeTab: observable,
    showDrawer:observable,
    isPrimaryDirty:observable,
    isError:observable,
    message:observable,

    changeTab: action,

    newCopyeditor:action,
    savePrimaryInfo: action,
    informPeerStores: action
  
})
