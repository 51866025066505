import React, { Component, Fragment } from 'react';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';

import CopyeditorJournalModal from './CopyeditorJournalModal';
import ArticleTypeModal from './ArticleTypeModal';

const addStyle = { backgroundColor: 'black',color:'white' };
const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class CopyeditorJournalList extends Component {

    
    constructor(props) {
        super(props);
    }

    deleteCellRenderer = (params) =>{
        return <Button onClick={this.handleDelete}>Delete</Button>
    }

    newJournal = (e) => {
        this.props.copyeditorStore.copyeditorJournalStore.newJournal(e);
    }
    deleteJournal = (params) => {
         const copyeditorUserId = params.data.userId;
         const userJournalId = params.data.usersJournalId;
         this.props.copyeditorStore.copyeditorJournalStore.deleteJournal(copyeditorUserId,userJournalId);
    }
    handleDelete = (e) => {
        console.log(e);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
       // this.gridApi.addEventListener('rowClicked', this.newJournal);
        params.api.sizeColumnsToFit();

        const store = this.props.copyeditorStore.copyeditorJournalListStore;
        store.fetchAssociatedJournals();
    }


    refresh = () => {
        const store = this.props.copyeditorStore.copyeditorJournalListStore;
        store.fetchAssociatedJournals();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.5;
        return { height: height };
    }

    journalSelected = (params) => {
        const copyeditorUserId = params.data.userId;
        const journalId = params.data.journalId;
        const articleTypeStore = this.props.copyeditorStore.articleTypeStore;
        articleTypeStore.fetchJournalArticleTypes(copyeditorUserId,journalId);
    }

    render() {
        const store = this.props.copyeditorStore.copyeditorJournalListStore;
        const journals = store.journals;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="add" onClick={this.newJournal} style={addStyle} icon="plus">New</Button>,
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle} />
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(journals)}
                <CopyeditorJournalModal copyeditorJournalStore = {this.props.copyeditorStore.copyeditorJournalStore}/>
                <ArticleTypeModal articleTypeStore = {this.props.copyeditorStore.articleTypeStore} />
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');
        const buttonLikeCell = params => {return <Button type="link">{params}</Button>};
        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    >
                        <AgGridColumn headerName="Journal Name" field="journalName"/>
                        <AgGridColumn headerName="Short Code" field="journalShortName"/>
                        <AgGridColumn headerName="Priority" field="priority"/>
                        <AgGridColumn headerName="WIP Limit" field="wipLimit"/>
                        <AgGridColumn headerName="Edit" field="edit"  onCellClicked = {(e)=>this.newJournal(e)} cellStyle={{ backgroundColor: '#aaffaa', border:'1px solid teal', borderRadius:'25px', width:'50px' }} />
                        <AgGridColumn headerName="Delete" field="del" onCellClicked = {(copyeditorUserId,usersJournalId)=>this.deleteJournal(copyeditorUserId,usersJournalId)} cellStyle={{ backgroundColor: '#FF0000', border:'1px solid teal', borderRadius:'25px', width:'60px' }}/>
                    </AgGridReact>
                </div>
            );
        }
    }

    renderRowData(journals) {
        if (this.gridApi) {
            this.gridApi.setRowData(journals);
        }
    }
}

export default CopyeditorJournalList;

