import React, { Component } from 'react';
import { inject } from 'mobx-react';

import CopyeditorPerformanceDrawer from './CopyeditorPerformanceDrawer';
import CopyeditorPerformanceList from './CopyeditorPerformanceList';

import CopyeditorPerformanceListStore from '../stores/CopyeditorPerformanceListStore';

@inject("appStore")
class CopyeditorPerformanceUI extends Component {

    constructor(props) {
        super(props);
        this.copyeditorPerformanceListStore = new CopyeditorPerformanceListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {

        return (
            <div>
                <CopyeditorPerformanceList copyeditorPerformanceListStore={this.copyeditorPerformanceListStore} />
                <CopyeditorPerformanceDrawer  copyeditorPerformanceListStore={this.copyeditorPerformanceListStore}/>
            </div>
        )
    }

}

export default CopyeditorPerformanceUI;
