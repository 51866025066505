import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';
import {humanizeDate} from '../stores/Util';
const columnDefs = [
    { headerName: "Journal Short Name", field: "journalShortName" },
    { headerName: "Article Name", field: "articleType" },
    { headerName: "Project Name", field: "projectName" },
    { headerName: "Waiting Since Date", field: "waitingSince"},
    //{ headerName: "Actual Start Date", field: "actualStartDate" },
    //{ headerName: "Expected End Date", field: "expectedEndDate" },
];

const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class TaskWaitingList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.taskSelected);
        params.api.sizeColumnsToFit();

        this.props.taskWaitingListStore.fetchTask();
    }

    refresh = () => {
        this.props.taskWaitingListStore.fetchTask();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    taskSelected = (params) => {

    }

    render() {
        const store = this.props.taskWaitingListStore;
        let tasks = store.tasks;
        for(var i = 0; i < tasks.length; i++)
        {
            tasks[i]["waitingSince"] =  humanizeDate(tasks[i]["waitingSince"])
        }
        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}/>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(tasks)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }


    renderRowData(tasks) {
        
        if (this.gridApi) {
            this.gridApi.setRowData(tasks);
        }
    }
}

export default TaskWaitingList;
