import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Tabs, Spin, Icon, message } from 'antd';


const { TabPane } = Tabs;

@observer
class JournalPerformanceDrawer extends Component {

    close = () => {

    }

    /**
     * Allow the user to change the tab only when the primary tab has been filled and saved.
     */
    changeTab = (tabIndex) => {

    }

    renderTab = (tabKey) => {


    }

    buildTabs = () => {

    }


    renderFooter = () => {
        return (
            <div></div>
        )
    }

    render() {
        return (
            <Drawer title={"Hi"} width={"45%"} closable={true} onClose={this.close} visible={false} destroyOnClose>
                {this.buildTabs()}
                {this.renderFooter()}
            </Drawer>
        );
    }
}

export default JournalPerformanceDrawer