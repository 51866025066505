import { decorate, observable, flow, action } from 'mobx';
import { journalsUrl,journalAssociationUrl,journalsDeleteUrl } from './APIEndpoints';
const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';


export default class CopyeditorJournalStore {

    state = PENDING;
    journals = [];
    showModal = false;

    constructor(props) {
        this.apiProxy = props.apiProxy;
        this.copyeditorJournalListStore = props.copyeditorJournalListStore;
    }

    /**
     * Fetch the List of Journals the Log-in User is associated to.
     * Please note that this is NOT the journals the Copyeditor is associated with.
     */
    fetchJournals = async () => {

        this.state = PENDING;

        this.journals = [];

        try {
            const response = await this.apiProxy.getAsync(journalsUrl);
            const data = await response.json();

            this.journals = data;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    fetchDeleteJournals = async (copyeditorUserId,userJournalId) => {

        this.state = PENDING;

        this.journals = [];

        try {
            const url = journalsDeleteUrl.replace("{copyeditorUserId}",copyeditorUserId).replace('{usersJournalId}',userJournalId);
            const response = await this.apiProxy.asyncPost(url);
            const data = await response.json();
            this.journals = data;
            this.state = DONE;
            window.location.reload(false);
        }
        catch (e) {
            this.state = ERROR;
        }
    }
    newJournal = async() => {
        await this.fetchJournals();
        this.showModal = true;
    }
    deleteJournal = async(copyeditorUserId,userJournalId) => {
        await this.fetchDeleteJournals(copyeditorUserId,userJournalId);
        this.showModal = false;
    }
    /**
     * Associate the selected Journal with the Copyeditor User Id
     */
    saveJournal = async (values) => {

        this.state = PENDING;
        this.isError = false;
        this.message = '';
        
        const userId = this.copyeditorJournalListStore.userId;

        try {

            const response = await this.apiProxy.asyncPost(journalAssociationUrl, {userId:userId, ...values });
            const data = await response.json();
            this.copyeditorJournalListStore.fetchAssociatedJournals();
            this.state = DONE;
            this.showModal = false;
        }

        catch (e) {
            this.state = ERROR;
        }
    }
}

decorate(CopyeditorJournalStore, {
    state: observable,
    journal: observable,
    showModal: observable,

    fetchJournals: action,
    newJournal: action,
    saveJournal: action,
    deleteJournal: action
})