import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge } from 'antd';
import {humanizeDate} from '../stores/Util';

const columnDefs = [
    { headerName: "User Id", field: "userId" },
    { headerName: "Journal Id", field: "journalId" },
    { headerName: "Freelancer Name", field: "userName" },
    { headerName: "Email Id", field: "emailAddress" },
    { headerName: "Journal Name", field: "journalName" },
    { headerName: "Journal Short Name", field: "journalShortName" },
    { headerName: "Article Type", field: "articleType" },
    { headerName: "Project Id", field: "projectId" },
    { headerName: "Project Name", field: "projectName" },
    { headerName: "Task Id", field: "taskId" },
    //{ headerName: "Task Name", field: "taskName" },
    { headerName: "Start Date", field: "actualStartDate"},
    { headerName: "End Date", field: "expectedEndDate"},
    { headerName: "Ms Pages", field: "msPages" },
    
];

const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class WIPALLSummaryList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        //this.gridApi.addEventListener('rowClicked', this.userSelected);
        params.api.sizeColumnsToFit();

        this.props.wipallListStore.fetch();
    }

    refresh = () => {
        this.props.wipallListStore.fetch();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.2;
        return { height: height };
    }

    userSelected = (params) => {
        this.props.taskListStore.fetchTasks(params.data.userId, params.data.fullName);
    }

    render() {
        const store = this.props.wipallListStore;
        let users = store.users;
        for(var i = 0; i < users.length; i++)
        {
            users[i]["actualStartDate"] =  humanizeDate(users[i]["actualStartDate"])
            users[i]["expectedEndDate"] =  humanizeDate(users[i]["expectedEndDate"])
        }
        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount} style={badgeStyle}></Badge>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(users)}
            </Fragment>
        );
    }


    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(users) {
        if (this.gridApi) {
            this.gridApi.setRowData(users);
            
        }
    }
}

export default WIPALLSummaryList;
