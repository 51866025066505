import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Drawer } from 'antd';
import CopyeditorPeformanceReport from './CopyeditorPerformanceReport';

@inject("drawerStore")
@observer
class CopyeditorPerformanceDrawer extends Component {

    close = () => {
        const { drawerStore } = this.props;
        drawerStore.toggle();
    }

    render() {
        const { drawerStore, copyeditorPerformanceListStore } = this.props;
        const selected = copyeditorPerformanceListStore.selected;
        const title = selected.fullName + ' performance report'
        return (
            <Drawer title={title} width={"45%"} closable={true} onClose={this.close} visible={drawerStore.showDrawer} destroyOnClose>
                <CopyeditorPeformanceReport copyeditorPerformanceListStore = {copyeditorPerformanceListStore} />
            </Drawer>
        );
    }
}

export default CopyeditorPerformanceDrawer