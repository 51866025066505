import React, { Component } from "react";
import { Card, Typography,Icon } from 'antd';

const { Title, Text } = Typography;

const cardStyle = {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
};

const aboutStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
};

const noticeStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop:'5%',
    justifyContent: 'left',
    alignItems: 'left'
};

const purposeStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop:'5%',
    justifyContent: 'left',
    alignItems: 'left'
};


class About extends Component {
    render() {
        return (
            <Card style={cardStyle} title={<Title level={2}>Support</Title>}>
                <div style={aboutStyle}>
                    <Text>Freelancer Management</Text>
                    <Text>Version 1.0</Text>
                    <Text>Aug-2019</Text>
                    <Text><Icon type="copyright" />&nbsp;Newgen KnowlegeWorks</Text>
                </div>
                <div style={purposeStyle}>
                    <Text>The primary objectives of this micro-site are:</Text> 
                    <Text><Icon type="caret-right" theme="filled"/>&nbsp;to maintain the context of Copyeditors</Text>
                    <Text><Icon type="caret-right" theme="filled"/>&nbsp;to monitor the workload and their performance</Text>
                </div>
                <div style={noticeStyle}>
                    <Text>Some of the listed features are being developed and will be available shortly.</Text>
                    <Text>Looking forward to your support and encouragement.</Text>
                    <Text>&nbsp;</Text>
                    <Text>Thank you!!!</Text>
                </div>
            </Card>
        )
    }
}

export default About;