import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Select, Tooltip, Icon,Checkbox,Row, Col } from 'antd';


const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


@observer
class ArticleTypeForm extends Component {

    saveArticleTypes = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const store = this.props.articleTypeStore;
                store.save(values);
            }
        });
    }

    renderCheckboxes = (journalArticleTypes) => {
        return (
            journalArticleTypes.map(item =>
                <Col key={item.articleTypeId}>
                    <Checkbox value={item.articleTypeId} key={item.articleTypeId} >{item.articleType}</Checkbox>
                </Col>
            )
        )
    }
    
    render() {

        const store = this.props.articleTypeStore;
        const journalArticleTypes = store.journalArticleTypes;

        const { getFieldDecorator } = this.props.form;
        return (

            <Form {...formItemLayout} onSubmit={this.saveArticleTypes} >
                <Form.Item
                    label={
                        <span>
                            Article Types&nbsp;
                                <Tooltip title="Article Types the Copyeditor for the Journal can work">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {
                        getFieldDecorator('selected', {
                            initialValue: store.selected
                        })
                        (
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row key="row">
                                    {this.renderCheckboxes(journalArticleTypes)}
                                </Row>
                            </Checkbox.Group>
                        )
                    }

                </Form.Item>
                <Button onClick={this.saveArticleTypes} type="primary">Save</Button>
            </Form >

        );
    }

}

export default Form.create()(ArticleTypeForm);
