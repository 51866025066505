import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react";
import { humanizeDate } from '../util/Util';
import { Button, Badge, PageHeader } from "antd";

const columnDefs = [
    { headerName: "Short Name", field: "journalShortName" },
    { headerName: "Article Type", field: "articleType" },
    { headerName: "DOI", field: "projectName" },
    { headerName: "Start Date", field: "actualStartDate", valueFormatter: params => humanizeDate(params.data["actualStartDate"]) },
    { headerName: "End Date", field: "actualEndDate", valueFormatter: params => humanizeDate(params.data["actualEndDate"]) },
    { headerName: "Bussiness Days", field: "daysTaken" },
];
const badgeStyle = { backgroundColor: '#52c41a' };

@observer
class CopyeditorPeformanceReport extends Component {

    onGridReady = (params) => {
        if (!params) {
            return;
        }
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.5;
        return { height: height };
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        return (
            <div className={grid_classes} style={this.titleHeight()}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowSelection={'single'}
                    onGridReady={this.onGridReady}
                    enableSorting={true}
                    enableFilter={true}
                    enableColResize={true}
                />
            </div>
        );
    }

    renderRowData(data) {
        if (this.gridApi) {
            this.gridApi.setRowData(data);
        }
    }

    render() {
        const grid_classes = ["ag-theme-balham"].join(' ');
        const store = this.props.copyeditorPerformanceListStore;
        return (
            <div className={grid_classes} style={this.titleHeight()}>
                <PageHeader
                    extra={[
                        <Badge key="counts" overflowCount={99999} count={store.selectedCopyeditorReport.length} style={badgeStyle} />
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(store.selectedCopyeditorReport)}
            </div>
        );

    }
}
export default CopyeditorPeformanceReport;