import { decorate, observable, action } from 'mobx';

export default class CopyeditorPageSummaryStore {

    userId = -1;
    fullName = "";
    emailAddress = "";
    monthlyMinPages = 0;
    monthlyMaxPages = 0;

    barData = [];
    monthlyLineData = [];
    accumulatedLineData = [];

    activeTab = "0";

    graphTabs = [
        {
            "label": "Accumulated",
            "key": "accumulatedLine",
        },
        {
            "label": "Monthly (Line)",
            "key": "monthlyLine",
        },
        {
            "label": "Monthly (Bar)",
            "key": "monthlyBar",
        }
    ];


    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    buildGraphData = (months, data) => {
        this.userId = data.userId;
        this.fullName = data.fullName;
        this.emailAddress = data.emailAddress;
        this.monthlyMinPages = data.monthlyMinPages;
        this.monthlyMaxPages = data.monthlyMaxPages;

        this.captureMonthlyLineSummary(months, data);
        this.captureAccumulatedLineSummary(months, data);
        this.captureBarSummary(months, data);
    }

    /**
     * The months are expected to be in a sorted order.
     * 
     */
    captureBarSummary = async (months, data) => {

        this.barData = [];

        months.map(item => {
            const el = { "month": item.headerName, "pages": data[item.field] }
            this.barData.push(el);
        })

    }

    captureAccumulatedLineSummary = (months, data) => {
        this.accumulatedLineData = [];

        const allocations = [];
        const maxs = [];
        const mins = [];

        var sumAllocation = 0;
        var sumMax = 0;
        var sumMin = 0;

        for (var i = months.length - 1; i >= 0; i--) {
            const value = data[months[i].field];

            sumAllocation = sumAllocation + value;
            sumMax = sumMax + this.monthlyMaxPages;
            sumMin = sumMin + this.monthlyMinPages;

            const allocationEl = { "x": months[i].headerName, "y": sumAllocation }
            const maxEl = { "x": months[i].headerName, "y": sumMax }
            const minEl = { "x": months[i].headerName, "y": sumMin }

            allocations.push(allocationEl);
            maxs.push(maxEl);
            mins.push(minEl);
        }

        allocations.reverse();
        maxs.reverse();
        mins.reverse();

        this.accumulatedLineData.push({ id: "Allocated", color: "green", data: allocations });
        this.accumulatedLineData.push({ id: "Max", color: "blue", data: maxs });
        this.accumulatedLineData.push({ id: "Min", color: "red", data: mins });
    }

    captureMonthlyLineSummary = (months, data) => {
        this.monthlyLineData = [];

        const allocations = [];
        const maxs = [];
        const mins = [];

        months.map(item => {
            const el = { "x": item.headerName, "y": data[item.field] }
            allocations.push(el);
        })

        months.map(item => {
            const el = { "x": item.headerName, "y": this.monthlyMaxPages }
            maxs.push(el);
        })

        months.map(item => {
            const el = { "x": item.headerName, "y": this.monthlyMinPages }
            mins.push(el);
        })

        this.monthlyLineData.push({ id: "Allocated", color: "green", data: allocations });
        this.monthlyLineData.push({ id: "Max", color: "blue", data: maxs });
        this.monthlyLineData.push({ id: "Min", color: "red", data: mins });

    }

}

decorate(CopyeditorPageSummaryStore, {
    userId:observable,
    fullName:observable,
    emailAddress:observable,
    
    barData: observable,
    monthlyLineData: observable,
    accumulatedLineData: observable,
    activeTab: observable,

    buildGraphData: action,
    captureBarSummary: action,
    captureAccumulatedLineSummary: action,
    captureMonthlyLineSummary: action
})
