import React, { Component } from 'react';
import { inject } from 'mobx-react';

import WIPDrawer from './WIPDrawer';
import WIPSummaryList from './WIPSummaryList';

import TaskListStore from '../stores/TaskListStore';
import WIPListStore from '../stores/WIPListStore';


@inject("appStore")
class WIPUI extends Component {

    constructor(props) {
        super(props);
        this.wipListStore = new WIPListStore({ apiProxy: props.appStore.apiProxy });
        this.taskListStore = new TaskListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {

        return (
            <div>
                <WIPSummaryList wipListStore={this.wipListStore} taskListStore={this.taskListStore}/>
                <WIPDrawer taskListStore={this.taskListStore} />
            </div>
        )
    }

}

export default WIPUI;

