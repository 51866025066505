import { decorate, observable, flow } from 'mobx';

import { performanceReportUrl, copyeditorPerformanceReportUrl } from './APIEndpoints';
import { drawerStore } from './DrawerStore';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class CopyeditorPerformanceListStore {

    users = [];
    listState = PENDING;
    rowCount = 0;
    selected={}
    selectedCopyeditorReport = [];
  
    constructor(props) {
      this.apiProxy = props.apiProxy;   
    }
    
    fetch = flow(function* () {
      this.listState = PENDING;
  
      try {
  
        const response = yield this.apiProxy.getAsync(performanceReportUrl);
        const data = yield response.json();
  
        this.users = data;
        this.rowCount = data.length;
        this.listState = DONE;
      }
      catch (e) {
        this.listState = ERROR;
      };
    });

    setSelected = flow(function* (data) {
      this.selected = data;
      drawerStore.toggle();
      this.listState = PENDING;
      try {
  
        const response = yield this.apiProxy.getAsync(copyeditorPerformanceReportUrl.replace('{copyeditorUserId}',this.selected.userId));
        const data = yield response.json();
  
        this.selectedCopyeditorReport = data;
        this.listState = DONE;
      }
      catch (e) {
        console.log(e);
        this.listState = ERROR;
      };
  });
}  
  decorate(CopyeditorPerformanceListStore, {
    listState: observable,
    users: observable,
    rowCount: observable,
    selected: observable,
    selectedCopyeditorReport: observable,
  })
