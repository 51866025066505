import { decorate, observable, flow } from 'mobx';

import { copyeditorJournalsUrl,journalAssociationUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class CopyeditorJournalListStore {

    userId = -1;
    
    journals = [];
    listState = PENDING;
    rowCount = 0;

    showDeleteModal = false;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetchAssociatedJournals = flow(function* () {
        this.listState = PENDING;

        try {
            const url = copyeditorJournalsUrl.replace('{copyeditorUserId}',this.userId)
            const response = yield this.apiProxy.getAsync(url);
            const data = yield response.json();
            for(var i=0;i<data.length;i++)
            {
                data[i]["edit"] = "edit";
                data[i]["del"] = "delete";
            }
            this.journals = data;
            this.rowCount = data.length;
            this.listState = DONE;
        }
        catch (e) {
            this.listState = ERROR;
        };
    });

    accept = (userId) => {
        this.userId = userId;
    }

    deleteJournalAssociation = async(userId,journalId) => {

        this.state = PENDING;
        this.isError = false;
        this.message = '';
        
        try {

            const response = await this.apiProxy.asyncDelete(journalAssociationUrl, {userId:userId, journalId:journalId });
            const data = await response.json();
            this.fetchAssociatedJournals();
            this.state = DONE;
            this.showDeleteModal = false;
        }

        catch (e) {
            this.state = ERROR;
        }
    }
}

decorate(CopyeditorJournalListStore, {
    listState: observable,
    journals: observable,
    rowCount: observable,
    showDeleteModal:observable
})
